import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = () => {
  return (
    <main className="mt-20 lg:mt-32 p-10 md:p-16 mx-auto max-w-4xl">
      <h1 className="text-base uppercase tracking-wider font-bold">
        Thank you
      </h1>
      <h2 className="my-10 mb-12 text-4xl font-bold leading-snug">
        We've received your message
      </h2>
      <p className="mb-10">We'll get back to you as soon as we can.</p>
    </main>
  )
}

const ThankYouPage = ({ location }) => (
  <Layout
    location={location}
    header={`dark`}
    image="https://images.unsplash.com/photo-1489533119213-66a5cd877091?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80"
  >
    <SEO title="Thank you!" />
    <Content />
  </Layout>
)

export default ThankYouPage
